/*
Sabina Piccini
*/
 
/* Reset */
@import './scss/reset';
@import './scss/variables';

#content {
  max-width: 410px;
  margin: 0 auto;
}

h1 {
  width: 100%;
  background: rgba(255, 255, 255, 0.75);
  top: 0;
  text-align: center;
  font-family: Avenir;
  font-size: 35px;
  text-transform: uppercase;
  padding: 20px 0;
} 

h2 {
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  max-width: 360px;
}

h3 {
  display: inline-block;
  margin-bottom: 10px;
  font-family: Avenir;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 400;
  border-bottom: 1px solid #000;
  display: inline-block;
}

#content {
  margin-top: 100px;
  font-size: 20px;
}

.contact {
  font-size: 16px;
}

.avatar {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 75px;
  margin: -125px auto 0;
  position: relative;
  top: 75px;

  img {
    width: 100%;
  }
}

.video {
  margin: 15px auto 0;
}

.jobDescription {
  font-size: 20px;
  font-style: italic;
  margin-top: 60px;
  text-align: center;
  font-weight: bold;

  em {
    font-style: italic;
    font-weight: bold;
    display: block;
  }
}

.copy {
  background: rgba(255, 255, 255, 0.75);
  border-radius: 25px;
  margin: 0 auto;
  padding: 2em 1em 1.5em;
  max-width: 550px;
}

@media (max-width: 550px) {
  .copy {
    margin: 0 5vw;
  }
}


.credits {
  margin: 35px 0 25px;

  .creditName {    
    color: #000;
    display: block;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.25rem;
    letter-spacing: .00937em;
    font-family: Roboto,Helvetica,Arial,sans-serif;
  }

  .creditSubText {
    color: #666;
    display: block;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: .01786em;
    font-family: Roboto,Helvetica,Arial,sans-serif;
  }
}

// @media (min-width: 550px) {
//   .bio {
//     display: flex;
//   }
// }
