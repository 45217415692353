body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: #fff url('/src/images/background.jpg') fixed center top no-repeat;
  background-size: cover;
  padding-bottom: 2em;
}

figure {
  background:#fff url('/src/images/placeholder.png') center center no-repeat;
}

.gallery img {
  max-height: 150px;
  display:inline-block;
}