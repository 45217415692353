/* Reset Defaults */

html,body,div,ul,ol,li,dl,dt,dd,h1,h2,h3,h4,h5,h6,pre,form,p,blockquote,fieldset,input,figure { margin: 0; padding: 0; }
h1,h2,h3,h4,h5,h6,pre,code,address,caption,cite,code,em,strong,th { font-size: 1em; font-weight: normal; font-style: normal; }
ul,ol { list-style: none; }
fieldset,img { border: none; }
caption,th { text-align: left; }
table { border-collapse: collapse; border-spacing: 0; }
ul.inline li {display:inline;}
acronym {text-decoration:none; border:none;}

/* Generic */
body {font-family:Arial, Helvetica, sans-serif; font-size:0.75em;}

/* Clearfix */
.clearfix:after { content:"."; display:block; height:0; clear:both; visibility:hidden;}
.clearfix {	display:inline-block;}
/* mac hide \*/
* html .clearfix {height: 1%;}
.clearfix {display: block;}
/* End hide */

html {
	height: 100%;
	margin-bottom: 1px;
}

.hide {display:none!important;}

video {
	width: 100%    !important;
	height: auto   !important;
}